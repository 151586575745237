import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {
  CheckUnsavedFormDialogService,
  CodebookService,
  IczFormControl,
  IczFormGroup,
  IczValidators,
  IFormGroupCheckable,
  injectModalData,
  injectModalRef,
  interpolateBackendErrorMessage,
  LoadingIndicatorService,
  namedDtosToOptions,
  Option,
  RegistryOfficeToastService,
  RegistryOfficeToastType
} from '|shared';
import {ApiRegistryOfficeTransferService, RegistryOfficeTransferTakeoverDto} from '|api/document';
import {InternalNotificationKey} from '|api/notification';
import {TranslateService} from '@ngx-translate/core';
import {SearchRecordSourceRegistryOfficeTransferActivityDto} from '|api/elastic';


@Component({
  selector: 'icz-transfer-activity-takeover-dialog',
  templateUrl: './transfer-activity-takeover-dialog.component.html',
  styleUrls: ['./transfer-activity-takeover-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    CheckUnsavedFormDialogService
  ]
})
export class TransferActivityTakeoverDialogComponent implements OnInit, IFormGroupCheckable {

  protected loadingService = inject(LoadingIndicatorService);
  protected modalRef = injectModalRef<Nullable<boolean>>();
  private apiRegistryOfficeTransferService = inject(ApiRegistryOfficeTransferService);
  private codebookService = inject(CodebookService);
  protected checkUnsavedService = inject(CheckUnsavedFormDialogService);
  private registryOfficeToastService = inject(RegistryOfficeToastService);
  private translateService = inject(TranslateService);
  protected selection = injectModalData<SearchRecordSourceRegistryOfficeTransferActivityDto[]>();

  form = new IczFormGroup({
    registryOfficeId: new IczFormControl<Nullable<number>>(null, [IczValidators.required()]),
    showRegistryOfficeSelector: new IczFormControl<boolean>(false),
  });

  formGroupsToCheck!: string[];
  registryOfficeOpitons: Option[] = [];

  ngOnInit(): void {
    this.checkUnsavedService.addUnsavedFormCheck(this, ['form']);

    this.loadingService.doLoading(
      this.codebookService.registryOffices(),
      this
    ).pipe(namedDtosToOptions).subscribe(offices => {
      this.registryOfficeOpitons = offices;
      this.form.get('registryOfficeId')!.setValue(this.selection[0].registryOfficeId!);
    });
  }

  submit() {
    const body: RegistryOfficeTransferTakeoverDto = {
      registryOfficeId: this.form.get('registryOfficeId')!.value!,
      transferActivityIds: this.selection.map(s => s.id!)
    };

    this.loadingService.doLoading(
      this.apiRegistryOfficeTransferService.registryOfficeTransferTransferTakeover({
        body
      }),
      this
    ).subscribe({
        next: _ => {
        this.modalRef.close(true);
      },
        error: err => {
        this.registryOfficeToastService.dispatchErrorToast(RegistryOfficeToastType.RO_TRANSFER_TAKEOVER_ERROR, {
          [InternalNotificationKey.ERROR_DESCRIPTION]: interpolateBackendErrorMessage(this.translateService, err.error)
        });
      }
    });
  }

  cancel() {
    this.modalRef.close(null);
  }

}
