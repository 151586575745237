import {ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit} from '@angular/core';
import {
  ApplicationConfigService,
  CheckUnsavedFormDialogService,
  IczFormControl,
  IczFormGroup,
  IczValidators,
  IFormGroupCheckable,
  injectModalRef,
  LoadingIndicatorService,
  NewStorageUnitDialogResult
} from '|shared';
import {RetentionTriggerTypeCode, StorageUnitForm} from '|api/commons';
import {ApiStorageUnitService, StorageUnitCreateDto} from '|api/document';
import {ApiIdentifierGeneratorService} from '|api/records';

@Component({
  selector: 'icz-new-storage-unit-dialog',
  templateUrl: './new-storage-unit-dialog.component.html',
  styleUrls: ['./new-storage-unit-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    CheckUnsavedFormDialogService
  ]
})
export class NewStorageUnitDialogComponent implements OnInit, IFormGroupCheckable {

  protected loadingService = inject(LoadingIndicatorService);
  protected modalRef = injectModalRef<Nullable<NewStorageUnitDialogResult>>();
  private destroyRef = inject(DestroyRef);
  private apiStorageUnitService = inject(ApiStorageUnitService);
  private checkUnsavedService = inject(CheckUnsavedFormDialogService);
  private applicationConfigService = inject(ApplicationConfigService);
  private apiIdentifierGeneratorService = inject(ApiIdentifierGeneratorService);

  form = new IczFormGroup({
    description: new IczFormControl<Nullable<string>>(null),
    disposalScheduleId: new IczFormControl<Nullable<number>>(null, [IczValidators.required()]),
    disposalYear: new IczFormControl<Nullable<number>>(null, [IczValidators.required()]),
    enforceStrictDisposalSchedule: new IczFormControl<Nullable<boolean>>(null, [IczValidators.required()]),
    enforceStrictDisposalYear: new IczFormControl<Nullable<boolean>>(false, [IczValidators.required()]),
    enforceStrictEntityClass: new IczFormControl<Nullable<boolean>>(false, [IczValidators.required()]),
    entityClassId: new IczFormControl<Nullable<number>>(null),
    externalRetentionTriggerId: new IczFormControl<Nullable<number>>(null),
    name: new IczFormControl<Nullable<string>>(null, [IczValidators.required()]),
    physicalLocation: new IczFormControl<Nullable<string>>(null),
    retentionTriggerTypeCode: new IczFormControl<Nullable<RetentionTriggerTypeCode>>(null),
    retentionTriggerYear: new IczFormControl<Nullable<number>>(null),
    storageUnitForm: new IczFormControl<Nullable<StorageUnitForm>>(null, [IczValidators.required()]),
    storageUnitNumber: new IczFormControl<Nullable<string>>(null, [IczValidators.required()]),
  });

  formGroupsToCheck!: string[];

  ngOnInit(): void {
    this.checkUnsavedService.addUnsavedFormCheck(this, ['form']);

    this.loadingService.doLoading(
      this.apiIdentifierGeneratorService.identifierGeneratorGenerateStorageUnitNumber(),
      this
    ).subscribe(storageUnitNumber => {
      this.form.get('storageUnitNumber')!.setValue(storageUnitNumber.identifier);
    });

    if (this.applicationConfigService.requireEntityClassConsistency) {
      this.form.get('entityClassId')!.setValidators([IczValidators.required()]);
      this.form.get('enforceStrictEntityClass')!.setValue(true);
    }
    if (this.applicationConfigService.requireDisposalYearConsistency) {
      this.form.get('enforceStrictDisposalYear')!.setValue(true);
    }
    if (this.applicationConfigService.forceRequiredDisposalSchedule) {
      this.form.get('enforceStrictDisposalSchedule')!.setValue(true);
    }
  }

  submit(redirectToPermissionSettings: boolean) {
    const formValue = this.form.getRawValue();

    const requestDto: StorageUnitCreateDto = {
      description: formValue.description,
      disposalScheduleId: formValue.disposalScheduleId,
      disposalYear: formValue.disposalYear,
      enforceStrictDisposalSchedule: formValue.enforceStrictDisposalSchedule!,
      enforceStrictDisposalYear: formValue.enforceStrictDisposalYear!,
      enforceStrictEntityClass: formValue.enforceStrictEntityClass!,
      entityClassId: formValue.entityClassId,
      externalRetentionTriggerId: formValue.externalRetentionTriggerId,
      name: formValue.name!,
      physicalLocation: formValue.physicalLocation,
      retentionTriggerTypeCode: formValue.retentionTriggerTypeCode,
      retentionTriggerYear: formValue.retentionTriggerYear,
      storageUnitForm: formValue.storageUnitForm!,
      storageUnitNumber: formValue.storageUnitNumber!,
    };

    this.loadingService.doLoading(
      this.apiStorageUnitService.storageUnitCreate({
        body: requestDto,
      }),
      this,
    ).subscribe(storageUnit => {
      this.modalRef.close({
        storageUnit,
        redirectToPermissionSettings,
      });
    });
  }

  cancel() {
    this.modalRef.close(null);
  }

}
