<form [formGroup]="form" [waiting]="loadingService.isLoading$(this) | async">
  <div class="row h-full">
    <div class="col grow">
      <icz-form-autocomplete
        formControlName="registryOfficeId"
        label="Zvolte spisovnu"
        [options]="registryOfficeOpitons"
      ></icz-form-autocomplete>
    </div>
  </div>
</form>

<icz-form-buttons>
  <icz-button leftButtons iczFormSubmit
              [disabled]="loadingService.isLoading(this)"
              (onAction)="submit()"
              primary label="Předat do spisovny"
  ></icz-button>
  <icz-button rightButtons
              [disabled]="loadingService.isLoading(this)"
              (onAction)="cancel()" label="Storno"
  ></icz-button>
</icz-form-buttons>
