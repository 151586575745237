import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {
  CheckUnsavedFormDialogService,
  CodebookService,
  IczFormControl,
  IczFormGroup,
  IczValidators,
  IFormGroupCheckable,
  injectModalData,
  injectModalRef,
  interpolateBackendErrorMessage,
  isDocumentObject,
  LoadingIndicatorService,
  namedDtosToOptions,
  Option,
  RegistryOfficeToastService,
  RegistryOfficeToastType
} from '|shared';
import {
  ApiRegistryOfficeTransferService,
  DocumentDto,
  FileDto,
  RegistryOfficeTransferCreateDto,
  StorageUnitDto
} from '|api/document';
import {InternalNotificationKey} from '|api/notification';
import {TranslateService} from '@ngx-translate/core';
import {EntityType} from '|api/commons';

export type RegistryOfficeTransferrableObject = FileDto | DocumentDto | StorageUnitDto;

@Component({
  selector: 'icz-transfer-to-registry-office-dialog',
  templateUrl: './transfer-to-registry-office-dialog.component.html',
  styleUrls: ['./transfer-to-registry-office-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    CheckUnsavedFormDialogService
  ]
})
export class TransferToRegistryOfficeDialogComponent implements OnInit, IFormGroupCheckable {

  protected loadingService = inject(LoadingIndicatorService);
  protected modalRef = injectModalRef<Nullable<boolean>>();
  private codebookService = inject(CodebookService);
  private apiRegistryOfficeTransferService = inject(ApiRegistryOfficeTransferService);
  protected checkUnsavedService = inject(CheckUnsavedFormDialogService);
  private registryOfficeToastService = inject(RegistryOfficeToastService);
  private translateService = inject(TranslateService);
  protected selection = injectModalData<RegistryOfficeTransferrableObject[]>();

  form = new IczFormGroup({
    registryOfficeId: new IczFormControl<Nullable<number>>(null, [IczValidators.required()]),
  });

  formGroupsToCheck!: string[];
  registryOfficeOpitons: Option[] = [];

  ngOnInit(): void {
    this.checkUnsavedService.addUnsavedFormCheck(this, ['form']);

    this.loadingService.doLoading(
      this.codebookService.registryOffices(),
      this
    ).pipe(namedDtosToOptions).subscribe(offices => {
      this.registryOfficeOpitons = offices;
    });
  }

  submit() {
    const body: RegistryOfficeTransferCreateDto = {
      registryOfficeId: this.form.get('registryOfficeId')!.value!,
      transferEntities: this.selection.map(s => ({
        entityId: s.id!,
        entityType: isDocumentObject(s) ? EntityType.DOCUMENT : s.entityType!
      }))
    };

    this.loadingService.doLoading(
      this.apiRegistryOfficeTransferService.registryOfficeTransferTransferCreate({
        body
      }),
      this
    ).subscribe({
        next: _ => {
          this.form.markAsPristine();
          this.modalRef.close(true);
        },
        error: err => {
          this.registryOfficeToastService.dispatchErrorToast(RegistryOfficeToastType.RO_TRANSFER_CREATE_ERROR, {
            [InternalNotificationKey.ERROR_DESCRIPTION]: interpolateBackendErrorMessage(this.translateService, err.error)
          });
        }
      });
  }

  cancel() {
    this.modalRef.close(null);
  }

}
