<form [formGroup]="formGroup" [waiting]="loadingService.isLoading$(this) | async">
  <div class="row">
    @if (isValidationDone) {
      <div class="col grow">
        @if (isDeativationValid) {
          <div>
            <icz-alert
              severity="warning" class="mb-32"
              heading="fe.ui.deactivationHeading"
              [textContext]="headingContext"
            ></icz-alert>
            <icz-form-field
              formControlName="reason"
              [label]="data.isDocument ? 'Důvod zrušení dokumentu' : 'Důvod zrušení spisu'"
            ></icz-form-field>
          </div>
        }
        @else {
          <icz-alert
            severity="error" class="mb-32"
            heading="fe.ui.deactivationHeadingError"
            [textContext]="headingContext"
          ></icz-alert>
          @for (errorMessage of validationErrors; track errorMessage) {
            <div class="items-start mb-16 row">
              <icz-alert [label]="errorMessage.code! | translate | interpolateBackendError:errorMessage.parameters!" alertMode="compact" severity="error"></icz-alert>
            </div>
          }
        }
      </div>
    }
  </div>
</form>

<icz-form-buttons>
  <icz-button leftButtons iczFormSubmit
    [disabled]="loadingService.isLoading(this) || !isDeativationValid"
    (onAction)="submit()"
    primary [label]="data.isDocument ? 'Zrušit dokument' : 'Zrušit spis'"
  ></icz-button>
  <icz-button rightButtons
    [disabled]="loadingService.isLoading(this)"
    (onAction)="cancel()" label="Storno"
  ></icz-button>
</icz-form-buttons>
