<form [formGroup]="form" [waiting]="loadingService.isLoading$(this) | async">
  <div class="row h-full">
    <div class="col grow gap-16">
      @if (!isBulkOperation) {
        <icz-entity-info [entity]="data.selectedObjects[0]"></icz-entity-info>
      }
      <icz-date-picker [label]="data.isSuspend ? 'Datum přerušení řízení' : 'Datum obnovení řízení'" formControlName="date"></icz-date-picker>
      <icz-form-field [label]="data.isSuspend ? 'Důvod přerušení řízení' : 'Důvod obnovení řízení'" formControlName="reason"></icz-form-field>
    </div>
  </div>
</form>

<icz-form-buttons>
  <icz-button leftButtons iczFormSubmit
    [disabled]="loadingService.isLoading(this)"
    (onAction)="submit()"
    primary [label]="data.isSuspend ? 'Přerušit řízení' : 'Obnovit řízení'"
  ></icz-button>
  <icz-button rightButtons
    [disabled]="loadingService.isLoading(this)"
    (onAction)="cancel()" label="Storno"
  ></icz-button>
</icz-form-buttons>
