import {Component, inject, OnInit, ViewChild} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {TranslateService} from '@ngx-translate/core';
import {combineLatest} from 'rxjs';
import {ClassificationSchemeDto, EntityClassDto, FileTypeDto} from '|api/codebook';
import {
  ApiFileService,
  ApiSettlementService,
  DisposalScheduleComputationDto,
  DisposalScheduleComputationInnerRequestDto,
  DisposalScheduleComputationRequestDto,
  FileCloseDto,
  FileDto,
  FilePrepareCompletionFileTypeDto,
  SimplifiedDisposalScheduleDto
} from '|api/document';
import {InternalNotificationKey} from '|api/notification';
import {
  CheckUnsavedFormDialogService,
  FileToastService,
  FileToastType,
  formatAsLocalIsoDate,
  getEntityClassSelectorSchema,
  IczFormControl,
  IczFormGroup,
  IczValidators,
  IFormGroupCheckable,
  injectModalData,
  injectModalRef,
  InMemorySearchDatasource,
  interpolateBackendErrorMessage,
  LoadingIndicatorService,
  namedDtoToOption,
  Option,
  TableColumnsData,
  TabsComponent,
  VisibleClassifiersMode
} from '|shared';
import {
  AbstractFileSettleCloseDialogComponent
} from '|modules/documents/modules/document-shared/components/file-settle-dialog/abstract-file-settle-close-dialog.component';
import {RetentionTriggerTypeCode} from '|api/commons';
import {
  FileSettleStateService
} from '|modules/documents/modules/document-shared/components/file-settle-dialog/file-settle-state.service';

export function getFileCloseForm(): IczFormGroup {
  return new IczFormGroup({
    entityClassId: new IczFormControl<Nullable<number>>(null, [IczValidators.required()]),
    fileTypeId: new IczFormControl<Nullable<number>>(null),
    closeDate: new IczFormControl<Nullable<string>>({value: formatAsLocalIsoDate(new Date()), disabled: true}),
    disposalScheduleId: new IczFormControl<Nullable<number>>(null, [IczValidators.required()]),
    disposalOperationCode: new IczFormControl<Nullable<string>>({value: null, disabled: true}),
    retentionPeriod: new IczFormControl<Nullable<number>>({value: null, disabled: true}),
    minDisposalOperationCode: new IczFormControl<Nullable<string>>({value: null, disabled: true}),
    minRetentionPeriod: new IczFormControl<Nullable<number>>({value: null, disabled: true}),
    yearOfRetentionPeriodStart: new IczFormControl<Nullable<string>>({value: null, disabled: true}),
    retentionTriggerTypeCode: new IczFormControl<Nullable<RetentionTriggerTypeCode>>({value: null, disabled: true}),
    triggerEventCheckYear: new IczFormControl<Nullable<string>>({value: null, disabled: true}, [IczValidators.min((new Date()).getFullYear())]),
    externalRetentionTriggerIds: new IczFormControl<Nullable<string>>(null),
    classificationSchemeName: new IczFormControl<Nullable<string>>({value: null, disabled: true}, []),
    classificationSchemeValidFrom: new IczFormControl<Nullable<string>>({value: null, disabled: true}, []),
    classificationSchemeValidTo: new IczFormControl<Nullable<string>>({value: null, disabled: true}, []),
  });
}

@Component({
  selector: 'icz-file-close-dialog',
  templateUrl: './file-close-dialog.component.html',
  styleUrls: ['./file-close-dialog.component.scss'],
  providers: [
    CheckUnsavedFormDialogService,
    FileSettleStateService
  ]
})
export class FileCloseDialogComponent extends AbstractFileSettleCloseDialogComponent implements OnInit, IFormGroupCheckable {

  protected loadingService = inject(LoadingIndicatorService);
  protected modalRef = injectModalRef<Nullable<boolean>>();
  protected apiFileService = inject(ApiFileService);
  private checkUnsavedService = inject(CheckUnsavedFormDialogService);
  private apiSettlementService = inject(ApiSettlementService);
  protected fileToastService = inject(FileToastService);
  private translateService = inject(TranslateService);
  protected override objects = injectModalData<FileDto[]>();

  @ViewChild('tabsComponent') tabsComponent!: TabsComponent;

  override generalTabForm = getFileCloseForm();

  singleFileSettlementDate!: Date;

  formGroupsToCheck!: string[];
  fileTypes: FileTypeDto[] = [];
  classificationSchemes: ClassificationSchemeDto[] = [];
  allEntityClasses: EntityClassDto[] = [];
  availableDisposalSchedules: Record<string, SimplifiedDisposalScheduleDto[]> = {};
  availableEntityClasses: Record<string, EntityClassDto[]> = {};
  availableFileTypes: Record<string, FilePrepareCompletionFileTypeDto[]> = {};

  generalTabEntityClassOptions: Option[] = [];
  generalTabDisposalScheduleIds: number[] = [];
  generalTabFileTypeOptions: Option[] = [];
  allEntityClassesDataSource = new InMemorySearchDatasource(() => []);

  entityClassSelectorSchema: TableColumnsData<keyof EntityClassDto> = new TableColumnsData<never>([]);

  globalDisposalSchedulePrepare: Nullable<DisposalScheduleComputationDto>;
  disposalSchedulePreparePerFile: Nullable<DisposalScheduleComputationDto[]>;

  readonly VisibleClassifiersMode = VisibleClassifiersMode;

  getFileTabForm(fileId: number): IczFormGroup {
    return this.forms[String(fileId)] as IczFormGroup;
  }

  get submitLabel() {
    return this.isBulk ? 'Uzavřít spisy' : 'Uzavřít spis';
  }

  getDisposalSchedulePreparePerDocument(fileId: number): Nullable<DisposalScheduleComputationDto> {
    if (this.disposalSchedulePreparePerFile) {
      return this.disposalSchedulePreparePerFile.find(dsp => dsp.entityId === fileId)!;
    } else {
      return null;
    }
  }

  override ngOnInit(): void {
    super.ngOnInit();

    if (!this.objects?.length) return;
    this.isBulk = this.objects.length > 1;
    this.checkUnsavedService.addUnsavedFormCheck(this, ['generalTabForm']);
    this.initTabs();

    this.initForms();

    if (this.isBulk) {
      this.initBulkCloseGeneralForm();
      this.loadingService.doLoading(
        combineLatest([
          this.apiFileService.filePrepareClose({body: this.objects.map(f => f.id)}),
          this.codebookService.classificationSchemes(),
          this.codebookService.disposalSchedules(),
          this.codebookService.securityCategories(),
          this.codebookService.entityClasses(),
        ]),
        this
      ).subscribe(([
          bulkFilePrepare,
          classificationSchemes,
          disposalSchedules,
          securityCategories,
          entityClasses
        ]) => {
        this.allEntityClassesDataSource = new InMemorySearchDatasource(() => bulkFilePrepare.availableEntityClasses!);
        this.generalTabForm.get('retentionPeriod')!.setValue(bulkFilePrepare.retentionPeriod);
        this.generalTabForm.get('disposalOperationCode')!.setValue(bulkFilePrepare.disposalOperationCode);
        this.generalTabEntityClassOptions = bulkFilePrepare.availableEntityClasses!.map(ec => namedDtoToOption()(ec));
        this.generalTabFileTypeOptions = bulkFilePrepare.availableFileTypes!.map(ft => {return {label: ft.label!, value: ft.id!};});
        this.generalTabDisposalScheduleIds = bulkFilePrepare.availableDisposalSchedules!.map(ds => ds.disposalScheduleId!);

        bulkFilePrepare.filePrepare!.forEach(fp => {
          this.availableDisposalSchedules[fp.fileId!] = fp.availableDisposalSchedules!;
          this.availableEntityClasses[fp.fileId!] = fp.availableEntityClasses!;
          this.availableFileTypes[fp.fileId!] = fp.availableFileTypes!;
          this.forms[String(fp.fileId!)].get('retentionPeriod')!.setValue(fp.retentionPeriod);
          this.forms[String(fp.fileId!)].get('disposalOperationCode')!.setValue(fp.disposalOperationCode);
        });

        this.allEntityClasses = entityClasses;
        this.classificationSchemes = classificationSchemes;

        this.entityClassSelectorSchema = getEntityClassSelectorSchema(disposalSchedules, securityCategories);
      });

    } else {
      this.loadingService.doLoading(
        combineLatest([
          this.apiFileService.filePrepareClose({body: [this.objects[0].id]}),
          this.apiSettlementService.settlementFindById({id: this.objects[0].settlementId!}),
          this.codebookService.classificationSchemes(),
        ]),
        this
      ).subscribe(([filePrepare, fileSettlement, classificationSchemes]) => {
        this.singleFileSettlementDate = new Date(fileSettlement.settlementDate!);
        this.classificationSchemes = classificationSchemes;
        this.availableFileTypes[String(this.objects[0].id)] = filePrepare.availableFileTypes!;
        this.availableEntityClasses[String(this.objects[0].id)] = filePrepare.availableEntityClasses!;
        this.bulkPrepareDisposalSchedules();
      });
    }
  }

  private initForms() {
    this.objects.forEach(f => {
      const newFileForm = getFileCloseForm();
      newFileForm.get('fileTypeId')!.setValue(f.fileTypeId);
      newFileForm.get('entityClassId')!.setValue(f.entityClassId);
      newFileForm.get('disposalScheduleId')!.setValue(f.disposalScheduleId);
      this.forms[f.id] = newFileForm;
    });
  }

  private bulkPrepareDisposalSchedules() {
    const innerReq: DisposalScheduleComputationInnerRequestDto[] = [];
    this.objects.forEach(f=> {
      innerReq.push({
        entityId: f.id,
        entityClassId: this.forms[f.id].get('entityClassId')!.value
      });
    });
    const prepareReq: DisposalScheduleComputationRequestDto = {
      referenceDate: (new Date()).toISOString(),
      inner: innerReq
    };
    this.apiFileService.fileGetValidDisposalSchedules({body: prepareReq}).subscribe(result => {
      this.globalDisposalSchedulePrepare = result.globalResult;
      this.disposalSchedulePreparePerFile = result.resultsPerEntity;
    });
  }

  private initBulkCloseGeneralForm() {
    this.generalTabForm.get('entityClassId')!.clearValidators();
    this.generalTabForm.get('disposalScheduleId')!.clearValidators();

    if (this.objects.every(d => d.entityClassId === this.objects[0].entityClassId)) {
      this.generalTabForm.get('entityClassId')!.setValue(this.objects[0].entityClassId);
    }
    if (this.objects.every(d => d.disposalScheduleId === this.objects[0].disposalScheduleId)) {
      this.generalTabForm.get('disposalScheduleId')!.setValue(this.objects[0].disposalScheduleId);
    }
    if (this.objects.every(d => d.fileTypeId === this.objects[0].fileTypeId)) {
      this.generalTabForm.get('fileTypeId')!.setValue(this.objects[0].fileTypeId);
    }

    this.generalTabForm.addControl('useGlobalSettings', new IczFormControl(false));

    this.generalTabForm.get('entityClassId')!.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      const entityClass = this.allEntityClasses.find(ec => ec.id === value)!;
      const classificationScheme = this.classificationSchemes.find(cs => cs.id === entityClass.classificationSchemeId)!;

      this.generalTabForm.get('classificationSchemeName')!.setValue(classificationScheme!.name);
      this.generalTabForm.get('classificationSchemeValidFrom')!.setValue(classificationScheme!.validFrom);
      this.generalTabForm.get('classificationSchemeValidTo')!.setValue(classificationScheme!.validTo);
      this.bulkPrepareDisposalSchedules();
    });
    const overrideFields: string[] = [
      'entityClassId',
      'fileTypeId',
      'disposalScheduleId',
    ];
    this.setupGeneralTabValueOverride(overrideFields);
  }

  private toggleShowValidity(show: boolean) {
    this.tabs.forEach(tab => {
      if (tab.id !== this.GENERAL_TAB) {
        tab.showTabValidity = show;
      }
    });
    this.changeDetectorRef.detectChanges();
  }

  submit() {
    if (this.isBulk) {
      this.toggleShowValidity(true);

      const allFormsValid = Object.values(this.forms).every(f => f.valid);
      if (allFormsValid) {
        const closeDtos: FileCloseDto[] = [];
        Object.entries(this.forms).forEach(([fileId, form]) => {
          const formValue = form.getRawValue() as FileCloseDto;
          closeDtos.push({
            fileId: parseInt(fileId),
            fileTypeId: formValue.fileTypeId!,
            closureDate: formValue.closureDate,
            disposalScheduleId: formValue.disposalScheduleId,
            entityClassId: formValue.entityClassId,
            triggerEventCheckYear: formValue.triggerEventCheckYear,
            yearOfRetentionPeriodStart: formValue.yearOfRetentionPeriodStart,
          });
        });
        this.loadingService.doLoading(
          this.apiFileService.fileBulkCloseFiles({body: closeDtos}),
          this,
        ).subscribe({
          next: _ => {
            this.fileToastService.dispatchBulkOperationStartedToast(FileToastType.BULK_FILE_SETTLEMENT_CLOSE_SUCCESS, {
              [InternalNotificationKey.COUNT]: this.objects.length,
            });
            this.modalRef.close(true);
          },
          error: err => {
            this.fileToastService.dispatchFileErrorToast(FileToastType.BULK_FILE_SETTLEMENT_CLOSE_ERROR, {
              [InternalNotificationKey.ERROR_DESCRIPTION]: interpolateBackendErrorMessage(this.translateService, err.error),
            });
          }
        });
      } else {
        this.tabsComponent.selectAndScrollToFirstInvalid();
      }
    } else {
      const formValue = this.getFileTabForm(this.objects[0].id).getRawValue();
      const requestDto: FileCloseDto = {
        entityClassId: formValue.entityClassId!,
        fileTypeId: formValue.fileTypeId!,
        disposalScheduleId: formValue.disposalScheduleId!,
        triggerEventCheckYear: formValue.triggerEventCheckYear,
        yearOfRetentionPeriodStart: formValue.yearOfRetentionPeriodStart,
      };

      this.loadingService.doLoading(
        this.apiFileService.fileCloseFile({
          id: this.objects[0].id,
          body: requestDto,
        }),
        this,
      ).subscribe({
        next: _ => {
          this.fileToastService.dispatchFileInfoToast(FileToastType.FILE_CLOSED, {
            [InternalNotificationKey.FILE_ID]: this.objects[0].id,
            [InternalNotificationKey.FILE_SUBJECT]: this.objects[0].subject!,
          });
          this.modalRef.close(true);
        },
        error: err => {
          this.fileToastService.dispatchFileErrorToast(FileToastType.FILE_CLOSE_ERROR, {
            [InternalNotificationKey.ERROR_DESCRIPTION]: interpolateBackendErrorMessage(this.translateService, err.error),
          });
        }
      });
    }
  }

  cancel() {
    this.modalRef.close(null);
  }

}
