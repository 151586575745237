import {DocumentState} from '|api/commons';
import {DocumentSearchService, IczTableDataSource, SortParam} from '|shared';
import {SearchRecordSourceDocumentFileDto} from '|api/elastic';

export class FileDetailContentDatasource extends IczTableDataSource<SearchRecordSourceDocumentFileDto> {
  constructor(searchService: DocumentSearchService, fileId: number, documentStates?: DocumentState[]) {
    super(
      searchParams => {
        const sortOrder: SortParam<keyof SearchRecordSourceDocumentFileDto> = {
          fieldName: 'orderNumberInGivenFile',
          descending: false
        };

        if (searchParams.sort && !searchParams.sort.find(s => s.fieldName === 'orderNumberInGivenFile')) {
          searchParams.sort = [sortOrder as SortParam<string>].concat(searchParams.sort);
        }
        else {
          searchParams.sort = [sortOrder as SortParam<string>];
        }
        if (documentStates?.length) {
          searchParams.filter = [...searchParams.filter, {fieldName: 'documentState', value: documentStates.join(',')}];
        }

        return searchService.findAllDocumentsInFile(searchParams, fileId);
      },
    );
  }
}
