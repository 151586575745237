@if (isBulk && objects?.length) {
  <div class="h-full">
    <icz-tabs [tabs]="tabs"
      [waiting]="loadingService.isLoading(this)"
      #tabsComponent
      class="h-full overflow-hidden"
      (tabClick)="tabChange($any($event))"
      >
      <ng-template [iczTab]="GENERAL_TAB">
        <icz-general-file-close-form
          [form]="generalTabForm"
          [availableFileTypes]="generalTabFileTypeOptions"
          [allEntityClassesDataSource]="allEntityClassesDataSource"
          [disposalSchedulePrepare]="globalDisposalSchedulePrepare"
        ></icz-general-file-close-form>
      </ng-template>
      @for (file of objects; track file.id) {
        <ng-template [iczTab]="file.id!.toString()">
          <div class="close-form-modal-spacing close-form-height col grow">
            <icz-file-close-form
              [form]="getFileTabForm(file.id)"
              [disposalSchedulePrepare]="getDisposalSchedulePreparePerDocument(file.id)"
              [file]="file"
              [availableEntityClasses]="availableEntityClasses[file.id]"
              [availableFileTypes]="availableFileTypes[file.id]"
              [showFileInfo]="true"
              (closeValid)="tabValidityChanged(file.id.toString(), $event)"
            ></icz-file-close-form>
          </div>
        </ng-template>
      }
    </icz-tabs>
  </div>
}
@else {
  @if (objects?.length) {
    <icz-file-close-form
      [availableEntityClasses]="availableEntityClasses[objects[0].id]"
      [availableFileTypes]="availableFileTypes[objects[0].id]"
      [file]="objects[0]"
      [form]="forms[objects[0].id]"
      [settlementDate]="singleFileSettlementDate"
      class="px-24"
    ></icz-file-close-form>
  }
}

<icz-form-buttons>
  <icz-button leftButtons iczFormSubmit
    [disabled]="loadingService.isLoading(this)"
    (onAction)="submit()"
    primary [label]="submitLabel"
  ></icz-button>
  <icz-button rightButtons
    [disabled]="loadingService.isLoading(this)"
    (onAction)="cancel()" label="Storno"
  ></icz-button>
</icz-form-buttons>
