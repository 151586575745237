import {Component, inject, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {EsslErrorCodeExceptionDto} from '|api/commons';
import {ApiDocumentService, ApiFileService, DeactivationDto, PrepareDeactivationDto} from '|api/document';
import {InternalNotificationKey} from '|api/notification';
import {
  CheckUnsavedFormDialogService,
  DocumentToastService,
  DocumentToastType,
  FileToastService,
  FileToastType,
  IczFormControl,
  IczFormGroup,
  IczValidators,
  IFormGroupCheckable,
  injectModalData,
  injectModalRef,
  interpolateBackendErrorMessage,
  LoadingIndicatorService
} from '|shared';

export interface DocumentFileDeativationDialogData {
  entityId: number;
  subject: string;
  isDocument: boolean;
}

@Component({
  selector: 'icz-document-file-deativation-dialog',
  templateUrl: './document-file-deactivation-dialog.component.html',
  styleUrls: ['./document-file-deactivation-dialog.component.scss'],
  providers: [
    CheckUnsavedFormDialogService
  ]
})
export class DocumentFileDeactivationDialogComponent implements OnInit, IFormGroupCheckable {

  protected loadingService = inject(LoadingIndicatorService);
  protected modalRef = injectModalRef<Nullable<boolean>>();
  protected checkUnsavedService = inject(CheckUnsavedFormDialogService);
  private translateService = inject(TranslateService);
  private apiDocumentService = inject(ApiDocumentService);
  private apiFileService = inject(ApiFileService);
  private documentToastService = inject(DocumentToastService);
  private fileToastService = inject(FileToastService);
  protected data = injectModalData<DocumentFileDeativationDialogData>();

  formGroup = new IczFormGroup({
    reason: new IczFormControl<Nullable<string>>(null, [IczValidators.required()]),
  });

  formGroupsToCheck!: string[];

  isDeativationValid = false;
  isValidationDone = false;

  validationErrors: EsslErrorCodeExceptionDto[] = [];

  headingContext!: {entityTypeToHeading_1: string, entityTypeToHeading_2: string};

  ngOnInit() {
    const validationReq$ = this.data.isDocument ? this.apiDocumentService.documentPrepareDeactivation({id: this.data.entityId}) : this.apiFileService.filePrepareDeactivation({id: this.data.entityId});

    this.loadingService.doLoading(validationReq$, this).subscribe((validationResult: PrepareDeactivationDto) => {
      this.isDeativationValid = !isNil(validationResult.valid) ? validationResult.valid : true;
      this.validationErrors = !isNil(validationResult.errors) ? validationResult.errors : [];
      this.isValidationDone = true;
    });

    this.checkUnsavedService.addUnsavedFormCheck(this, ['formGroup']);

    if (this.data.isDocument) {
      this.headingContext = {
        entityTypeToHeading_1: this.translateService.instant('dokumentu'),
        entityTypeToHeading_2: this.translateService.instant('dokument'),
      };
    } else {
      this.headingContext = {
        entityTypeToHeading_1: this.translateService.instant('spisu'),
        entityTypeToHeading_2: this.translateService.instant('spis'),
      };
    }
  }

  submit() {
    const requestDto: DeactivationDto = {
      reason: this.formGroup.get('reason')!.value,
    };

    const deactivationReq$ = this.data.isDocument ?
      this.apiDocumentService.documentDeactivateDocument({id: this.data.entityId, body: requestDto}) :
      this.apiFileService.fileDeactivateFile({id: this.data.entityId, body: requestDto});

    this.loadingService.doLoading(
      deactivationReq$,
      this,
    ).subscribe({
      next: _ => {
        if (this.data.isDocument) {
          this.documentToastService.dispatchDocumentInfoToast(DocumentToastType.DOCUMENT_DEACTIVATED, {
            [InternalNotificationKey.DOCUMENT_ID]: this.data.entityId,
            [InternalNotificationKey.DOCUMENT_SUBJECT]: this.data.subject,
          });
        } else {
          this.fileToastService.dispatchFileInfoToast(FileToastType.FILE_DEACTIVATED, {
            [InternalNotificationKey.FILE_ID]: this.data.entityId,
            [InternalNotificationKey.FILE_SUBJECT]: this.data.subject,
          });
        }
        this.modalRef.close(true);
      },
      error: error => {
        if (this.data.isDocument) {
          this.documentToastService.dispatchDocumentErrorToast(DocumentToastType.DOCUMENT_DEACTIVATE_ERROR, {
            [InternalNotificationKey.ERROR_DESCRIPTION]: interpolateBackendErrorMessage(this.translateService, error.error),
          });
        } else {
          this.fileToastService.dispatchFileErrorToast(FileToastType.FILE_DEACTIVATION_ERROR, {
            [InternalNotificationKey.ERROR_DESCRIPTION]: interpolateBackendErrorMessage(this.translateService, error.error),
          });
        }
      }
    });
  }

  cancel() {
    this.modalRef.close(null);
  }
}
