@if (showTabs) {
  <div [waiting]="loadingService.isLoading$(this) | async" class="h-full">
    @if (isBulk) {
      <icz-tabs #tabsComponent
        [tabs]="tabs"
        (tabClick)="tabChange($any($event))"
        [withStickyTabs]="true"
        class="settle-dialog-height overflow-hidden">
        <ng-template [iczTab]="GENERAL_TAB">
          <div class="settle-form-modal-spacing col grow">
            <icz-general-file-settle-form
              [form]="generalTabForm"
              [minSettlementDate]="minSettlementDate"
              [bulkSettlementDate]="bulkSettlementDate"
              [disposalSchedulePrepare]="globalDisposalSchedulePrepare"
            ></icz-general-file-settle-form>
          </div>
          </ng-template>
          @for (file of objects; track file.id) {
            <ng-template [iczTab]="file.id!.toString()">
              <div class="settle-form-modal-spacing col grow">
                <icz-file-settle-form
                  (settlementValid)="tabValidityChanged(file.id.toString(), $event)"
                  [file]="file"
                  [form]="getFileTabForm(file.id)"
                  [settlementTabState]="tabsCache[file.id.toString()]"
                  [disposalSchedulePrepare]="getDisposalSchedulePreparePerDocument(file.id)"
                  (settlementTabPrepareChange)="settlementTabStateChange(file.id.toString(), $event)"
                  [showFileInfo]="true"
                ></icz-file-settle-form>
              </div>
            </ng-template>
          }
        </icz-tabs>
      }
    @else {
        <icz-wizard (canceled)="close(false)" (finished)="submit()" [alwaysEnableAllSteps]="true">
          <ng-template
            [customSubmitHandler]="quickSubmit"
            iczWizardStep="resolution"
            label="Vyřízení spisu"
            submitLabel="Vyřídit"
            >
            <div class="settle-form-modal-spacing col grow">
              <!-- choose first form in array as there is only one file to settle -->
              <icz-file-settle-form
                [settlementTabState]="tabsCache[objects[0].id.toString()]"
                [file]="objects[0]"
                [entityClasses]="entityClasses"
                [form]="getFileTabForm(objects[0].id)"
              ></icz-file-settle-form>
            </div>
          </ng-template>
          <ng-template
            [customSubmitHandler]="quickSubmit"
            iczWizardStep="content"
            label="Obsah spisu"
            submitLabel="Vyřídit"
          >
            @if (fileUnsettledDocuments?.length) {
              <icz-file-settle-file-content [fileId]="objects[0].id"
                                            [unsettledDocuments]="singleFileDocuments"
                                            [settlementDate]="settlementDateForSingleFile()">
              </icz-file-settle-file-content>
            }
          </ng-template>
        </icz-wizard>
      }
      @if (isBulk) {
        <icz-form-buttons>
          <icz-button (onAction)="submit()"
            primary
            label="Vyřídit"
          leftButtons></icz-button>
          <icz-button rightButtons (onAction)="close()"
          label="Storno"></icz-button>
        </icz-form-buttons>
      }
    </div>
  }
