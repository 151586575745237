<div class="col grow">
  <div [formGroup]="form" class="col grow">
    <div class="col grow">
      <div class="row px-8">
        <icz-document-type-selector
          [form]="form"
          class="grow"
          controlName="documentTypeId"
          [mode]="VisibleClassifiersMode.VALID_AT_GIVEN_DATE"
          [date]="modalData.settlementDate"
        ></icz-document-type-selector>
        <div class="grow"></div>
      </div>
      <div class="grow overflow-visible">
        <icz-digital-components-selector
          tableTitle="Výběr komponent"
          id="file-settle-document-detail-components"
          [view]="EsslComponentsTableColumnSet.ESSL_COMPONENTS_SETTLEMENT_SELECTOR"
          [documentId]="modalData.documentId"
          [acceptableDataFileFormatRules]="acceptableDataFileFormats"
          [disableFinalComponents]="true"
          [selection]="preselectedComponents"
          (esslComponentIdsExcluded)="setExcludedComponentIds($event)"
          class="grow"
        ></icz-digital-components-selector>
      </div>
    </div>
  </div>
</div>
<icz-form-buttons>
  <icz-button iczFormSubmit
              leftButtons
              (onAction)="confirm()"
              primary label="Potvrdit"
  ></icz-button>
  <icz-button rightButtons
              (onAction)="cancel()" label="Storno"
  ></icz-button>
</icz-form-buttons>
