import {Directive, EventEmitter, Output} from '@angular/core';
import {AbstractObjectSettleDialogComponent, Option} from '|shared';
import {DisposalScheduleComputationDto, FileDto, SimplifiedDisposalScheduleDto} from '|api/document';

@Directive()
export abstract class AbstractFileSettleCloseDialogComponent extends AbstractObjectSettleDialogComponent<FileDto> {

  protected overrideValueInTabs(field: string, value: any) {
    Object.values(this.forms).forEach(form => {
      form.get(field)?.setValue(value, {emitEvent: false});
      form.recursivelyUpdateValueAndValidity();
      this.syncFormsValidityWithTabValidity();
    });
  }

}

@Directive()
export abstract class AbstractFileSettleCloseFormComponent {

  @Output()
  availableDisposalSchedulesOwnChange = new EventEmitter<Nullable<SimplifiedDisposalScheduleDto[]>>();

  allowedDisposalSchedulesIds: number[] = [];
  disposalScheduleOptions: Option[] = [];

  protected processAvailableDisposalSchedules(validSchedule: DisposalScheduleComputationDto) {
    if (validSchedule.availableDisposalSchedules) {
      this.disposalScheduleOptions = [];
      this.allowedDisposalSchedulesIds = [];
      validSchedule.availableDisposalSchedules.forEach(ads => {
        this.disposalScheduleOptions.push({value: ads.disposalScheduleId!, label: ads.name!, data: ads});
        this.allowedDisposalSchedulesIds.push(ads.disposalScheduleId!);
        this.availableDisposalSchedulesOwnChange.emit(validSchedule.availableDisposalSchedules!);
      });
    }
  }
}
