<form [formGroup]="form" class="px-24">
  <icz-section label="Datum uzavření">
    <icz-date-picker class="grow" label="Datum uzavření" formControlName="closeDate" outputFormat="dateTime"></icz-date-picker>
  </icz-section>
  <icz-section label="Přenastavit hromadně pro všechny uzavírané spisy">
    <icz-checkbox label="Pro všechny vyřizované spisy přenastavit věcnou skupinu nebo typ spisu"
                  formControlName="useGlobalSettings"></icz-checkbox>
    @if (form.get('useGlobalSettings')!.value) {
      <div class="row grow">
        <div class="col grow">
          <icz-form-autocomplete
            label="Typ spisu"
            formControlName="fileTypeId"
            [options]="fileTypeOptions"
          ></icz-form-autocomplete>
          <!--        todo refactor entity class selector cmp to support inmemory source from parent components-->
          <icz-form-popup-table-selector
            popupTitle="Vyhledejte a vyberte věcnou skupinu"
            tableId="entity-class-search"
            label="Věcná skupina"
            modelValueKey="id"
            viewValueTemplate="{fqcCode} {name}"
            formControlName="entityClassId"
            tableTitle="Věcná skupina"
            [dataSource]="allEntityClassesDataSource"
            [tableColumnsData]="entityClassSelectorSchema"
            [showFulltextSearch]="false"
            [defaultFilterColumns]="['fqcCode', 'name']"
          ></icz-form-popup-table-selector>
          <icz-section label="Skartační režim">
            <div class="col grow">
              <icz-form-autocomplete
                [clearable]="false"
                [options]="disposalScheduleOptions"
                class="grow"
                formControlName="disposalScheduleId"
                label="Skartační režim"
              ></icz-form-autocomplete>
              <icz-form-autocomplete [options]="disposalOperationCodeOptions" class="grow-2"
                                     formControlName="disposalOperationCode" label="Skartační operace"></icz-form-autocomplete>
              <icz-form-field class="grow-1" formControlName="retentionPeriod" label="Skartační lhůta"></icz-form-field>
              <icz-form-autocomplete
                [clearable]="false"
                [options]="retentionTriggerTypesOptions"
                class="grow"
                formControlName="retentionTriggerTypeCode"
                label="Spouštěcí událost"
              ></icz-form-autocomplete>
              @if (disposalSchedulePrepare && isExternalRetentionTriggerTypeWithPeriod()) {
                <icz-ext-retention-trigger-selector
                  [form]="form"
                  [availableExternalRetentionTriggerIds]="disposalSchedulePrepare.externalRetentionTriggerIds ?? []"
                ></icz-ext-retention-trigger-selector>
              }
            </div>
            <div class="col grow">
              @if (isRetentionTriggerTypeWithPeriod()) {
                <div class="row">
                  <icz-form-field class="grow" label="Rok spouštecí události"  formControlName="yearOfRetentionPeriodStart" type="integer"></icz-form-field>
                  <icz-form-field class="grow" label="Rok kontroly spouštecí události"  formControlName="triggerEventCheckYear" type="integer"></icz-form-field>
                </div>
              }
            </div>
          </icz-section>
        </div>
        <div class="grow">
          <div class="row grow">
            <icz-form-field label="Spisový plán" formControlName="classificationSchemeName" class="grow"></icz-form-field>
          </div>
          <div class="row grow">
            <icz-date-picker label="Platnost spisového plánu od" formControlName="classificationSchemeValidFrom" class="grow" outputFormat="dateTime"></icz-date-picker>
            <icz-date-picker label="Platnost spisového plánu do" formControlName="classificationSchemeValidTo" class="grow" outputFormat="dateTime"></icz-date-picker>
          </div>
        </div>
      </div>
    }
  </icz-section>
</form>
