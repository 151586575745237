<icz-section label="Informace o spisu">
  <icz-entity-info [entity]="file"></icz-entity-info>
</icz-section>
<form [formGroup]="form" [waiting]="loadingService.isLoading(this)">
  <div class="col grow">
    <div class="row grow">
      <icz-section label="Zatřídění" class="grow">
        @if (availableFileTypes) {
          <icz-form-autocomplete
            [options]="fileTypeOptions"
            formControlName="fileTypeId"
            label="Typ spisu"
          ></icz-form-autocomplete>
        }
        @else {
          <icz-file-type-selector
            [date]="settlementDate"
            [form]="form"
            [mode]="VisibleClassifiersMode.VALID_AT_GIVEN_DATE"
            controlName="fileTypeId"
          ></icz-file-type-selector>
        }

        <!--        todo refactor entity class selector cmp to support inmemory source from parent components-->
        <icz-form-popup-table-selector
          popupTitle="Vyhledejte a vyberte věcnou skupinu"
          tableId="entity-class-search"
          label="Věcná skupina"
          modelValueKey="id"
          viewValueTemplate="{fqcCode} {name}"
          formControlName="entityClassId"
          tableTitle="Věcná skupina"
          [dataSource]="validEntityClassesDataSource"
          [tableColumnsData]="entityClassSelectorSchema"
          [showFulltextSearch]="false"
          [defaultFilterColumns]="['fqcCode', 'name']"
        ></icz-form-popup-table-selector>

      </icz-section>
      <icz-section label="Datum uzavření" class="grow">
        <div class="row">
          <icz-date-picker class="grow" label="Datum uzavření" formControlName="closeDate" outputFormat="dateTime"></icz-date-picker>
        </div>
      </icz-section>
    </div>
    <icz-section label="Skartační režim">
      <div class="gap-24 row grow">
        <div class="col grow">
          <icz-form-autocomplete
            [clearable]="false"
            [options]="disposalScheduleOptions"
            class="grow"
            formControlName="disposalScheduleId"
            label="Skartační režim"
          ></icz-form-autocomplete>
          <icz-form-autocomplete [options]="disposalOperationCodeOptions" class="grow-2"
                                 formControlName="disposalOperationCode" label="Skartační operace"></icz-form-autocomplete>
          <icz-form-field class="grow-1" formControlName="retentionPeriod" label="Skartační lhůta"></icz-form-field>
          <icz-form-autocomplete
            [clearable]="false"
            [options]="retentionTriggerTypesOptions"
            class="grow"
            formControlName="retentionTriggerTypeCode"
            label="Spouštěcí událost"
          ></icz-form-autocomplete>
          @if (disposalSchedulePrepare && isExternalRetentionTriggerTypeWithPeriod()) {
            <icz-ext-retention-trigger-selector
              [form]="form"
              [availableExternalRetentionTriggerIds]="disposalSchedulePrepare.externalRetentionTriggerIds ?? []"
            ></icz-ext-retention-trigger-selector>
          }
        </div>
        <div class="col grow">
          <icz-form-field label="Nejdelší skartační lhůta ve spisu"
                          formControlName="minRetentionPeriod"></icz-form-field>
          <icz-form-field label="Nejpřísnější skartační znak ve spisu"
                          formControlName="minDisposalOperationCode"></icz-form-field>
          @if (isRetentionTriggerTypeWithPeriod()) {
            <div class="row">
              <icz-form-field class="grow" label="Rok spouštecí události"  formControlName="yearOfRetentionPeriodStart" type="integer"></icz-form-field>
              <icz-form-field class="grow" label="Rok kontroly spouštecí události"  formControlName="triggerEventCheckYear" type="integer"></icz-form-field>
            </div>
          }
        </div>
      </div>
    </icz-section>
  </div>
</form>
