import {DocumentSearchService, IczTableDataSource} from '|shared';
import {SearchRecordSourceDocumentFileDto} from '|api/elastic';
import {of} from 'rxjs';

export class ObjectsForStorageUnitInsertDatasource extends IczTableDataSource<SearchRecordSourceDocumentFileDto> {
  constructor(searchService: DocumentSearchService, storageUnitId: Nullable<number>) {
    super(
      searchParams => {
        if (storageUnitId) {
          return searchService.findAllEntitiesForStorageUnitInsert(searchParams, storageUnitId);
        }
        else {
          return of({
            content: [],
            numberOfElements: 0,
            last: false,
            first: false,
            totalElements: 0
          });
        }
      },
    );
  }
}
