<icz-new-storage-unit-specification-form
  [waiting]="loadingService.isLoading(this)"
  class="block p-16"
  [form]="form"
></icz-new-storage-unit-specification-form>

<icz-form-buttons>
  <ng-container leftButtons>
    <icz-button iczFormSubmit
                [disabled]="loadingService.isLoading(this)"
                (onAction)="submit(false)"
                primary label="Založit"
    ></icz-button>
    <icz-button iczFormSubmit
                [disabled]="loadingService.isLoading(this)"
                label="Založit a přejít k nastavení oprávnění"
                (onAction)="submit(true)"
    ></icz-button>
  </ng-container>
  <icz-button rightButtons
              [disabled]="loadingService.isLoading(this)"
              (onAction)="cancel()" label="Storno"
  ></icz-button>
</icz-form-buttons>
