import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {
  CheckUnsavedFormDialogService,
  DocumentToastService,
  DocumentToastType,
  FileToastService,
  FileToastType,
  IczFormControl,
  IczFormGroup,
  IczValidators,
  IFormGroupCheckable,
  injectModalData,
  injectModalRef,
  interpolateBackendErrorMessage,
  isDocumentEntity,
  LoadingIndicatorService
} from '|shared';
import {ApiSettlementService, DocumentDto, FileDto} from '|api/document';
import {EntityType} from '|api/commons';
import {InternalNotificationKey} from '|api/notification';
import {TranslateService} from '@ngx-translate/core';

export interface SuspendOrResumeSettlementDialogData {
  isSuspend: boolean,
  selectedObjects: (DocumentDto | FileDto)[],
}

@Component({
  selector: 'icz-suspend-or-resume-settlement-dialog',
  templateUrl: './suspend-or-resume-settlement-dialog.component.html',
  styleUrls: ['./suspend-or-resume-settlement-dialog.component.scss'],
  providers: [
    CheckUnsavedFormDialogService
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuspendOrResumeSettlementDialogComponent implements OnInit, IFormGroupCheckable {

  protected loadingService = inject(LoadingIndicatorService);
  protected modalRef = injectModalRef<Nullable<boolean>>();
  protected checkUnsavedService = inject(CheckUnsavedFormDialogService);
  private apiSettlementService = inject(ApiSettlementService);
  private documentToastService = inject(DocumentToastService);
  private fileToastService = inject(FileToastService);
  private translateService = inject(TranslateService);
  protected data = injectModalData<SuspendOrResumeSettlementDialogData>();

  form = new IczFormGroup({
    date: new IczFormControl<string>((new Date()).toISOString(), [IczValidators.required()]),
    reason: new IczFormControl<Nullable<string>>(null, [IczValidators.required()]),
  });

  formGroupsToCheck!: string[];

  isBulkOperation = false;

  ngOnInit() {
    this.checkUnsavedService.addUnsavedFormCheck(this, ['form']);
    this.isBulkOperation = this.data.selectedObjects.length > 1;
  }

  submit() {
    const requestData = {...this.form.getRawValue(), operationEntities: this.data.selectedObjects.map(obj => ({entityType: isDocumentEntity(obj.entityType!) ? EntityType.DOCUMENT : EntityType.FILE, id: obj.id!}))};

    if (this.data.isSuspend) {
      this.loadingService.doLoading(
          this.apiSettlementService.settlementSuspendSettlements({
            body: {
              date: new Date(requestData.date).toISOString(),
              reason: requestData.reason!,
              entitiesForSuspend: requestData.operationEntities
            }
          }),
          this
      ).subscribe({
        next: _ => {
          if (this.isBulkOperation) {
            this.documentToastService.dispatchBulkOperationStartedToast(DocumentToastType.BULK_SETTLEMENT_SUSPEND_STARTED, {
              [InternalNotificationKey.COUNT]: this.data.selectedObjects.length
            });
          } else {
            if (isDocumentEntity(this.data.selectedObjects[0].entityType)) {
              this.documentToastService.dispatchDocumentInfoToast(DocumentToastType.SETTLEMENT_SUSPEND_STARTED, {
                [InternalNotificationKey.DOCUMENT_ID]: this.data.selectedObjects[0].id!,
                [InternalNotificationKey.DOCUMENT_SUBJECT]: this.data.selectedObjects[0].subject
              });
            } else {
              this.fileToastService.dispatchFileInfoToast(FileToastType.FILE_SETTLEMENT_SUSPEND_STARTED, {
                [InternalNotificationKey.FILE_ID]: this.data.selectedObjects[0].id!,
                [InternalNotificationKey.FILE_SUBJECT]: this.data.selectedObjects[0].subject
              });
            }
          }
          this.form.markAsPristine();
          this.modalRef.close(true);
        },
        error: err => {
          const errorData = {
            [InternalNotificationKey.ERROR_DESCRIPTION]: interpolateBackendErrorMessage(this.translateService, JSON.parse(err.error))
          };
          if (this.isBulkOperation) {
            this.documentToastService.dispatchDocumentErrorToast(DocumentToastType.BULK_SETTLEMENT_SUSPEND_ERROR, errorData);
          } else {
            if (isDocumentEntity(this.data.selectedObjects[0].entityType)) {
              this.documentToastService.dispatchDocumentErrorToast(DocumentToastType.SETTLEMENT_SUSPEND_ERROR, errorData);
            } else {
              this.fileToastService.dispatchFileErrorToast(FileToastType.FILE_SETTLEMENT_SUSPEND_ERROR, errorData);
            }
          }
        }
      });
    } else {
      this.loadingService.doLoading(
          this.apiSettlementService.settlementResumeSettlements({
            body: {
              date: new Date(requestData.date).toISOString(),
              reason: requestData.reason!,
              entitiesForResume: requestData.operationEntities
            }
          }),
          this
      ).subscribe({
        next: _ => {
          if (this.isBulkOperation) {
            this.documentToastService.dispatchBulkOperationStartedToast(DocumentToastType.BULK_SETTLEMENT_RESUME_STARTED, {
              [InternalNotificationKey.COUNT]: this.data.selectedObjects.length
            });
          } else {
            if (isDocumentEntity(this.data.selectedObjects[0].entityType)) {
              this.documentToastService.dispatchDocumentInfoToast(DocumentToastType.SETTLEMENT_RESUME_STARTED, {
                [InternalNotificationKey.DOCUMENT_ID]: this.data.selectedObjects[0].id!,
                [InternalNotificationKey.DOCUMENT_SUBJECT]: this.data.selectedObjects[0].subject
              });
            } else {
              this.fileToastService.dispatchFileInfoToast(FileToastType.FILE_SETTLEMENT_RESUME_STARTED, {
                [InternalNotificationKey.FILE_ID]: this.data.selectedObjects[0].id!,
                [InternalNotificationKey.FILE_SUBJECT]: this.data.selectedObjects[0].subject
              });
            }
          }
          this.form.markAsPristine();
          this.modalRef.close(true);
        },
        error: err => {
          const errorData = {
            [InternalNotificationKey.ERROR_DESCRIPTION]: interpolateBackendErrorMessage(this.translateService, JSON.parse(err.error))
          };
          if (this.isBulkOperation) {
            this.documentToastService.dispatchDocumentErrorToast(DocumentToastType.BULK_SETTLEMENT_RESUME_ERROR, errorData);
          } else {
            if (isDocumentEntity(this.data.selectedObjects[0].entityType)) {
              this.documentToastService.dispatchDocumentErrorToast(DocumentToastType.SETTLEMENT_RESUME_ERROR, errorData);
            } else {
              this.fileToastService.dispatchFileErrorToast(FileToastType.FILE_SETTLEMENT_RESUME_ERROR, errorData);
            }
          }
        }
      });
    }
  }

  cancel() {
    this.modalRef.close(null);
  }

}
